<template>
  <v-card>
    <v-card-text>
      Erzeugt für die Firma
      <strong>{{ $store.getters.currentCompany.name }}</strong> eine Übersicht
      aller Zinszahlungen
      <search-masterdata-typeahead
        :company-id="$store.getters.currentCompany.id"
        @itemSelected="(id) => (selectedItem = id)"
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn :disabled="!selectedItem" :loading="loading" @click="createReport"
        >Export</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import exportApi from '@/api/exportApi'
import SearchMasterdataTypeahead from '@/components/SearchMasterdataTypeahead'
import exportQueuesApi from '@/api/exportQueuesApi'

export default {
  name: 'ReportInterestPaymentsPerMunicipality',
  components: { SearchMasterdataTypeahead },
  data() {
    return {
      loading: false,
      selectedItem: null,
    }
  },
  methods: {
    async createReport() {
      try {
        this.loading = true
        const companyId = this.$store.getters.currentCompany.id
        const queue = await exportApi.interestPaymentsPerMunicipality(
          companyId,
          this.selectedItem
        )
        await exportQueuesApi.downloadWhenDone(queue.id, queue.fileName)
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
        throw e
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
