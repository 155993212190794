<template>
  <app-layout>
    <v-container grid-list-md>
      <v-layout row wrap>
        <company-selector />
        <v-expansion-panel>
          <v-expansion-panel-content :key="key" v-for="(item, key) in items">
            <template v-slot:header>
              <div class="subheading font-weight-bold">
                {{ item.heading }}
              </div>
            </template>
            <component :is="item.component" v-bind="{ ...item.props }" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-layout>
    </v-container>
  </app-layout>
</template>

<script>
import AppLayout from '@/components/AppLayout'
import ReportBelongedToGrid from '@/pages/reporting/ReportBelongedToGrid'
import ReportZeroMunicipalities from '@/pages/reporting/ReportZeroMunicipalities'
import ReportUniformMunicipalRate from '@/pages/reporting/ReportUniformMunicipalRate'
import ReportPaymentSummary from '@/pages/reporting/ReportPaymentSummary'
import ReportLiquidityPlanning from '@/pages/reporting/ReportLiquidityPlanning'
import ReportBusinessTaxPayments from '@/pages/reporting/ReportBusinessTaxPayments'
import ReportAllCompanyPayments from '@/pages/reporting/ReportAllCompanyPayments'
import ReportCompanyPaymentsPerMunicipality from '@/pages/reporting/ReportCompanyPaymentsPerMunicipality'
import ReportNumberOfPayments from '@/pages/reporting/ReportNumberOfPayments'
import ReportInterestPaymentsPerMunicipality from '@/pages/reporting/ReportInterestPaymentsPerMunicipality'
import ReportInterestPaymentsPerYear from '@/pages/reporting/ReportInterestPaymentsPerYear'
import CompanySelector from '@/components/CompanySelector'

export default {
  name: 'Reporting',
  components: {
    CompanySelector,
    ReportNumberOfPayments,
    ReportCompanyPaymentsPerMunicipality,
    ReportAllCompanyPayments,
    ReportBusinessTaxPayments,
    ReportLiquidityPlanning,
    ReportPaymentSummary,
    ReportUniformMunicipalRate,
    ReportZeroMunicipalities,
    ReportBelongedToGrid,
    ReportInterestPaymentsPerMunicipality,
    ReportInterestPaymentsPerYear,
    AppLayout,
  },
  computed: {
    items() {
      return [
        {
          component: 'ReportNumberOfPayments',
          heading: 'Anzahl Bescheide',
        },
        {
          component: 'ReportCompanyPaymentsPerMunicipality',
          heading: 'Gewerbesteuerzahlungen pro Kommune',
        },
        {
          component: 'ReportAllCompanyPayments',
          heading: 'Gesamtzahlungen pro Firma',
        },
        {
          component: 'ReportBusinessTaxPayments',
          heading: 'Gewerbesteuerzahlungen',
        },
        {
          component: 'ReportLiquidityPlanning',
          heading: 'Liquiditätsplanung',
        },
        {
          component: 'ReportPaymentSummary',
          heading: 'Zahlungen',
          props: { variant: 'default' },
        },
        {
          component: 'ReportPaymentSummary',
          heading: 'Zahlungen Listenform',
          props: { variant: 'fixed-cols' },
        },
        {
          component: 'ReportUniformMunicipalRate',
          heading: 'Ortsteile mit einh. Hebesatz',
        },
        {
          component: 'ReportZeroMunicipalities',
          heading: 'Null-Gemeinden',
        },
        {
          component: 'ReportBelongedToGrid',
          heading: 'Netzzugehörigkeit einer Kommune',
        },
        {
          component: 'ReportInterestPaymentsPerMunicipality',
          heading: 'Zinszahlungen einer Kommune',
        },
        {
          component: 'ReportInterestPaymentsPerYear',
          heading: 'Zinszahlungen pro Jahr',
        },
      ]
    },
  },
}
</script>

<style scoped></style>
