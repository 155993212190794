<template>
  <v-card>
    <v-card-text>
      Erzeugt einen Bericht über die Netzzugehörigkeit einer Kommune für das
      Unternehmen
      <strong>{{ $store.getters.currentCompany.name }}</strong
      >.
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn :loading="loading" @click="createReport">Export</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import exportApi from '@/api/exportApi'

export default {
  name: 'ReportBelongedToGrid',
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async createReport() {
      try {
        this.loading = true
        await exportApi.exportBelongsToGrid(
          this.$store.getters.currentCompany.id
        )
      } catch (e) {
        this.$root.setSnackbar('error', 'Fehler beim Export')
        throw e
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
