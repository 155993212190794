<template>
  <v-card>
    <v-card-text>
      Erzeugt für die Firma
      <strong>{{ $store.getters.currentCompany.name }}</strong> eine
      Liquiditätsplanung ab dem angegebenen Datum.
    </v-card-text>

    <v-card-text>
      <datepicker v-model="date" label="Planung ab.. " />
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn :loading="loading" @click="createReport">Export</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import exportApi from '@/api/exportApi'
import formatDate from 'date-fns/format'
import Datepicker from '@/components/dates/Datepicker'
import exportQueuesApi from '@/api/exportQueuesApi'

export default {
  name: 'ReportLiquidityPlanning',
  components: { Datepicker },
  data() {
    return {
      date: null,
      loading: false,
    }
  },
  methods: {
    async createReport() {
      try {
        this.loading = true
        const queue = await exportApi.liquidityPlanning(
          this.$store.getters.currentCompany.id,
          this.date
        )
        await exportQueuesApi.downloadWhenDone(queue.id, queue.fileName)
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
        throw e
      } finally {
        this.loading = false
      }
    },
  },
  created() {
    const today = new Date()
    this.date = formatDate(today, 'YYYY-MM-DD')
  },
}
</script>

<style scoped></style>
