<template>
  <v-card>
    <v-card-text>
      Erzeugt für die Firma
      <strong>{{ $store.getters.currentCompany.name }}</strong> eine Übersicht
      über aller Zahlungen.
    </v-card-text>
    <v-spacer />
    <v-card-actions>
      <v-spacer />
      <v-btn :loading="loading" @click="createReport">Export</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import exportApi from '@/api/exportApi'
import exportQueuesApi from '@/api/exportQueuesApi'

export default {
  name: 'ReportAllCompanyPayments',
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async createReport() {
      try {
        this.loading = true
        const queue = await exportApi.companyPaymentsPerYear(
          this.$store.getters.currentCompany.id
        )
        await exportQueuesApi.downloadWhenDone(queue.id, queue.fileName)
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
        throw e
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
