<template>
  <v-card flat>
    <v-card-text>
      Erzeugt für die Firma
      <strong>{{ $store.getters.currentCompany.name }}</strong> eine Übersicht
      über die Anzahl der Gewerbesteuerbescheide.
      <v-select :items="options" v-model="selectedOption" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        :disabled="!selectedOption"
        :loading="loading"
        @click="createReport"
        >Export</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import exportApi from '@/api/exportApi'
import exportQueuesApi from '@/api/exportQueuesApi'

export default {
  name: 'ReportNumberOfPayments',
  data() {
    return {
      loading: false,
      options: [
        { text: 'Gesamt', value: 'all' },
        { text: 'Vorauszahlungen', value: 'prepayments' },
        { text: 'Zinsbescheide', value: 'interest' },
        { text: 'Sonstige', value: 'tax' },
      ],
      selectedOption: 'all',
    }
  },
  methods: {
    async createReport() {
      try {
        this.loading = true
        const queue = await exportApi.numberOfPaymentsPerYear(
          this.$store.getters.currentCompany.id,
          this.selectedOption
        )
        await exportQueuesApi.downloadWhenDone(queue.id, queue.fileName)
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
        throw e
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
