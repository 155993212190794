<template>
  <v-card fill-height>
    <v-card-text>
      Erzeugt für die Firma
      <strong>{{ $store.getters.currentCompany.name }}</strong> einen Bericht
      über Zahlungen, welche im angegebenen Jahr stattgefunden haben.
    </v-card-text>

    <v-card-text>
      <yearpicker v-model="year" label="Jahr" />
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn :loading="loading" @click="createReport">Export</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import exportApi from '@/api/exportApi'
import exportQueuesApi from '@/api/exportQueuesApi'
import formatDate from 'date-fns/format'
import subYear from 'date-fns/sub_years'
import Yearpicker from '@/components/dates/Yearpicker'

export default {
  name: 'ReportPaymentSummary',
  components: { Yearpicker },
  props: {
    variant: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      year: null,
      loading: false,
    }
  },
  methods: {
    async createReport() {
      try {
        this.loading = true
        const companyId = this.$store.getters.currentCompany.id
        const queue = await exportApi.paymentSummary(
          companyId,
          this.year,
          this.variant
        )
        await exportQueuesApi.downloadWhenDone(queue.id, queue.fileName)
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
        throw e
      } finally {
        this.loading = false
      }
    },
  },
  created() {
    const today = new Date()
    this.year = formatDate(subYear(today, 1), 'YYYY')
  },
}
</script>

<style scoped></style>
