<template>
  <v-card>
    <v-card-text>
      Erzeugt für die Firma
      <strong>{{ $store.getters.currentCompany.name }}</strong> einen Bericht
      über Ortsteile, welche einen einheitlichen Hebesatz haben.
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn :loading="loading" @click="createReport">Export</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import exportApi from '@/api/exportApi'

export default {
  name: 'ReportUniformMunicipalRate',
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async createReport() {
      try {
        this.loading = true
        await exportApi.uniformMunicipalRate(
          this.$store.getters.currentCompany.id
        )
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
        throw e
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
