import BaseApi from './BaseApi'
import format from 'date-fns/format'
import { mimes } from './mimes'

const today = new Date()

class ExportApi extends BaseApi {
  exportBelongsToGrid(companyId) {
    const url = `companies/${companyId}/export/belongedToGrid`
    const fileName = `${format(
      today,
      'YYYY-MM-DD'
    )}-Netzzugehoerigkeit-${companyId}.xlsx`
    return this.createDownloadRequest(url, fileName)
  }

  zeroMunicipalities(companyId, year) {
    const url = `companies/${companyId}/export/zeroMunicipalities?year=${year}`
    const fileName = `${format(
      today,
      'YYYY-MM-DD'
    )}-Nullgemeinden-${year}-${companyId}.xlsx`
    return this.createDownloadRequest(url, fileName)
  }

  uniformMunicipalRate(companyId) {
    const url = `companies/${companyId}/export/municipalitiesWithUniformRate`
    const fileName = `${format(
      today,
      'YYYY-MM-DD'
    )}-OrtsteileEinhHS-${companyId}.xlsx`
    return this.createDownloadRequest(url, fileName)
  }

  paymentSummary(companyId, year, variant = 'default') {
    return this.get(
      `companies/${companyId}/export/paymentSummary?year=${year}&variant=${variant}`
    )
  }

  prepaymentsBIExport(companyId, quartal, year) {
    return this.get(
      `companies/${companyId}/export/bi?quartal=${quartal}&year=${year}`
    )
  }

  liquidityPlanning(companyId, date) {
    return this.get(
      `companies/${companyId}/export/liquidityPlanning?date=${date}`
    )
  }

  businessTaxPaymentsPerYear(companyId) {
    const url = `companies/${companyId}/export/businessTaxPaymentsPerYear`
    const fileName = `${format(
      today,
      'YYYY-MM-DD'
    )}-Gewerbesteuerzahlungen-${companyId}.xlsx`
    return this.createDownloadRequest(url, fileName)
  }

  companyPaymentsPerYear(companyId) {
    return this.get(`companies/${companyId}/export/masterData/paymentSummary`)
  }

  municipalityPaymentsPerYear(id) {
    return this.get(`export/masterData/${id}/paymentSummary`)
  }

  interestPaymentsPerMunicipality(companyId, masterDataId) {
    return this.get(
      `companies/${companyId}/export/interestPayments?masterDataId=${masterDataId}`
    )
  }

  interestPaymentsPerYear(companyId, year) {
    return this.get(
      `companies/${companyId}/export/interestPayments?year=${year}`
    )
  }

  numberOfPaymentsPerYear(companyId, type) {
    return this.get(
      `companies/${companyId}/export/numberOfPaymentsPerYear?type=${type}&isDownload=true`
    )
  }

  createDownloadRequest(url, fileName, mime = mimes.excel) {
    const method = 'get'
    return this.downloadAsFile({ url, method }, mime, fileName)
  }
}

export default new ExportApi()
