import BaseApi from './BaseApi'
import { mimes } from './mimes'

class ExportQueuesApi extends BaseApi {
  getAll() {
    return this.get(`/export/queues`)
  }

  getEntry(id) {
    return this.get(`/export/queues/${id}`)
  }

  download(id, fileName, mime = mimes.excel) {
    const url = `/export/queues/${id}/download`
    const method = 'get'
    return this.downloadAsFile({ url, method }, mime, fileName)
  }

  downloadWhenDone(id, fileName, mime) {
    if (!id) {
      throw new Error('Download konnte nicht gestartet werden')
    }
    return new Promise((resolve, reject) => {
      const intervalId = setInterval(async () => {
        const result = await this.getEntry(id)
        if (result.finished) {
          await this.download(id, fileName, mime)
          clearInterval(intervalId)
          resolve()
        }
      }, 2000)
    })
  }
}

export default new ExportQueuesApi()
